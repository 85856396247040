<template>
  <div class="sw-speed-reading-select-chapter">
    <div v-show="chapterList.length" class="sw-speed-reading-select-chapter-list">
      <div>
        <ul>
          <li v-for="(item, i) in chapterList" :key="i"
              :class="{'active':currentNode && currentNode.sjzjid === item.sjzjid}"
              @click="clickChapter(item)">
            <span>{{ item.zjmc }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="isLoading" class="sw-loading"></div>
    <div v-if="chapterList.length===0 " style="text-align:center;line-height:80vh;font-size: 3vw">没有更多数据了~~</div>
    <sw-back-out
        :backPre="`/sw/speedReading/training?lxid=${$route.query.lxid}&lxmc=${$route.query.lxmc}`"></sw-back-out>
  </div>
</template>

<script>
import {readBooksChapter} from '@/api/reading'

export default {
  data() {
    return {
      chapterList: [],
      selectedSpeed: '',
      emptyText: '',
      isLoading: false,
      flag: false,
      currentPage: 1,
      currentNode: undefined,
      isShowEmptyText: false
    }
  },
  watch: {
    'flag': function (newVal) {
      if (newVal && !this.isShowEmptyText) {
      }
    },
  },
  created() {
    this.chapterList = []
    this.getChapter().then(() => {
      if (this.chapterList.length > 0) {
        this.currentNode = this.chapterList[0];
      }
    });
  },
  methods: {
    async getChapter() {
      this.isLoading = true
      this.emptyText = ''
      const storedBook = localStorage.getItem('book');
      const storedSelectedSpeed = localStorage.getItem('selectedSpeed');

      const obj = storedBook ? JSON.parse(storedBook) : this.$store.state.speedReading.book;
      this.selectedSpeed = storedSelectedSpeed ? JSON.parse(storedSelectedSpeed) : this.$store.state.speedReading.selectedSpeed;
      console.log(obj, this.selectedSpeed, '嘿嘿')
      const {result, resultCode} = await readBooksChapter({
        sjid: obj.sjid
      })
      if (resultCode === 200) {
        this.chapterList = result
        console.log(this.chapterList.length,'长度')
        if (this.chapterList.length < 12) {
          this.isShowEmptyText = true
        } else {
          this.isShowEmptyText = false
        }
        this.isLoading = false
      } else {
        this.emptyText = '暂无数据'
        this.flag = false
      }
    },
    clickChapter(item) {
      this.currentNode = item
      console.log(item, '123243')
      this.$router.push({
        path: "/sw/speedReading/pagingReading",
        query: {
          lxid: this.$route.query.lxid,
          lxmc: this.$route.query.lxmc
        }
      });
      this.$store.commit('speedReading/setContent', item);
      localStorage.setItem('bookContent', JSON.stringify(item));
    }
  },
  mounted() {
    let ulDom = document.querySelector(".sw-speed-reading-select-chapter-list ul");
    let scrollDom = document.querySelector(".sw-speed-reading-select-chapter-list>div");
    let scrollHeight = ulDom.scrollHeight;
    let _this = this;
    scrollDom.onscroll = function () {
      let scrollTop = scrollDom.scrollTop;
      let clientHeight = scrollDom.clientHeight;
      if (clientHeight + scrollTop >= scrollHeight) {
        _this.flag = true
      }
    };
  }
};
</script>

<style lang="scss">
</style>