<template>
    <div  v-show="isShow">
        <sw-panel title="阅读结果">
            <div slot="body">
                <ul class="sw-speed-reading-result-list">
                    <li>文章字数 {{ bookDetail.bookWordNumber }}</li>
                    <li>阅读时间 {{ totalTimes }}</li>
                    <li>答题成绩 {{ gameAnserGrade }}</li>
                    <li>阅读速度 {{ bookDetail.bookWordNumber && $store.state.speedReading.totalTimes ?  Math.ceil(bookDetail.bookWordNumber / $store.state.speedReading.totalTimes) : 0 }}字/分钟</li>
                    <li>有效阅读速度 {{ bookDetail.bookWordNumber && $store.state.speedReading.totalTimes ?  Math.ceil(bookDetail.bookWordNumber * 60 / ($store.state.speedReading.totalTimes * 60 )* gameAnserGrade /100) : 0 }}字/分钟</li>
                </ul>
                <span class="sw-speed-reading-result-timg">{{msg[gameAnserGrade]}}</span>
            </div>
            <div slot="footer">
                <sw-button class="sw-btn" @click="clickBack">返回</sw-button>
                <sw-button class="sw-btn" @click="$router.push('/sw/speedReading/reading')">继续练习</sw-button>
            </div>
        </sw-panel>
    </div>
</template>

<script>
export default {
    name: "result",
    data() {
        return {
            isShow: false,
            totalTimes: 0,
            pageNum: 0,
            pageMax: 0,
            subjectList: [],
            bookDetail: this.$store.state.speedReading.bookDetail || {bookWordNumber:0},
            gameAnserGrade: this.$store.state.speedReading.anserGrade,
            msg: {
                0: '太糟糕了',
                20: '继续努力',
                40: '继续努力',
                60: '继续努力',
                80: '加油加油',
                100: '非常厉害',
            }
        };
    },
    created() {
        this.contTime();
    },
    methods: {
        clickBack() {
            this.$router.push(
                '/sw/speedReading/'+((this.$store.state.speedReading.currentCheckPoint == '3' || this.$store.state.speedReading.currentCheckPoint == '4')  ? 'training' : 'evaluation')
            );
        },
        contTime() {
            let times = this.$store.state.speedReading.totalTimes;
            let str = times + "";
            let m = str.split(".")[1] ? Math.round(("0." + str.split(".")[1]) * 60) : "";
            if(times > 1){
                let str = times + ''
                if(parseInt(times) > 60){
                    this.totalTimes = parseInt(times) + '时' + (parseInt(times) % 60) +'分' + (m ? m + '秒' : '')
                }else{
                    this.totalTimes = parseInt(times) + '分' + (m ? m + '秒' : '')
                }
            }else{
                this.totalTimes = (parseInt((times * 60 * 100)).toFixed(2)) / 100 + '秒'
            }
            this.$nextTick(()=>{
                this.isShow = true
            })
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
