<template>
  <div>
    <sw-button @click="clickBack" class="sw-btn-back" style="z-index: 10" title="退出"
               v-if="$route.query.lxmc==='阅读训练'">
      <i class="sw-btn-back-icon1"></i>
    </sw-button>
    <sw-button @click="$router.go(-1)" class="sw-btn-back" style="z-index: 10" title="退出"
               v-if="$route.query.lxmc==='能力测试'">
      <i class="sw-btn-back-icon1"></i>
    </sw-button>
    <!-- 阅读页面 -->
    <div class="sw-speed-reading-going">
      <div v-show="bookDetail && bookDetail.bookrackId"
           :style="{'justify-content': isReading ? 'flex-start' : 'space-around'}">
        <div class="sw-speed-reading-going-header">{{ bookDetail.bookName }}<span>【共{{
            bookDetail.bookWordNumber
          }}字】</span></div>
        <sw-button v-if="!isReading && $route.query.lxmc==='能力测试' " class="sw-btn" @click="startTest">开始测试
        </sw-button>
        <sw-button v-if="!isReading && $route.query.lxmc==='阅读训练'" class="sw-btn" @click="startReading">开始阅读
        </sw-button>
        <div class="sw-speed-reading-going-contain"
             v-if="$route.query.lxmc==='阅读训练' && isReading">
          <div class="sw-speed-reading-going-mask">
            <div class="sw-speed-reading-going-article" id="sw-speed-reading-going-article">
              <div class="sw-speed-reading-going-text">
                <p v-for="paragraph in paragraphs">{{ paragraph }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="container" v-if="$route.query.lxmc==='能力测试' && isReading  ">
          <div class="sw-speed-reading-flipbook">
            <template>
              <div class="sw-speed-reading-flipbook-viewport-mask"></div>
              <div class="sw-speed-reading-flipbook-viewport-text">{{ getVisibleContent }}</div>
              <div style="text-align: right;margin:0 20px 20px 0 ">
                <span style="padding-right: 5px">第{{ currentPage }}页&nbsp;/</span>
                <span>共{{ totalPages }}页</span>
              </div>
            </template>
          </div>
        </div>

        <div class="individualPage" style="position:absolute;opacity:-1;"
             v-if="$route.query.lxmc==='能力测试' && isReading "></div>
        <div class="sw-speed-reading-going-footers sw-speed-reading-going-read-footers"
             v-if="$route.query.lxmc==='能力测试' && isReading ">
          <sw-button
              class="sw-btn sw-game-goi-btn"
              @click="previousPage">
            上一页
          </sw-button>

          <sw-button
              @click="audioControls"
              class="sw-btn ">
            音乐
            <i class="iconfont" :class="{'iconbar-close':isMusic ? false:true,'iconbar-open':isMusic ? true:false}"></i>
          </sw-button>
          <b class="sw-speed-reading-going-footer-time" style="padding:15px 10px 20px 80px">{{ times }}</b>
          <sw-button
              @click="finished"
              class="sw-btn"
              :class="{'disabled':!complete? true : false}"
              :disabled="!complete"
          >
            阅读完成
          </sw-button>
          <sw-button
              class="sw-btn sw-game-goi-btn"
              @click="nextPage">
            下一页
          </sw-button>
        </div>


        <!-- 阅读训练 -自动开始阅读的页脚 begin -->
        <div class="sw-speed-reading-going-footer"
             style="display: flex; align-items: center; justify-content: space-between">
          <div v-if="this.$route.query.lxmc==='阅读训练' ">
            <!-- <span v-show="isReading && $store.state.speedReading.currentCheckPoint == '2'" style="float:left;">{{time}}</span> -->
            <span
                v-show="!isReading"
                class="sw-speed-reading-going-btn"
                :class="{'disabled':isDisabled2?true:false}"
                @click="speedSelect('-')">
                            -
                        </span>
            <span class="sw-speed-reading-going-btn-total-time">当前速度：{{ totalMinute }}字/分钟</span>
            <span
                v-show="!isReading"
                class="sw-speed-reading-going-btn"
                :class="{'disabled':isDisabled ?true:false}"
                @click="speedSelect('+')">
                            +
                        </span>
          </div>
          <span v-if="this.$route.query.lxmc==='阅读训练' ">(总耗时：{{ totalTimes }} )</span>
          <sw-button
              v-show="isReading && $route.query.lxmc==='阅读训练'"
              @click="audioControls"
              class="sw-btn">
            音乐
            <i class="iconfont" :class="{'iconbar-close':isMusic ? false:true,'iconbar-open':isMusic ? true:false}"></i>
          </sw-button>
        </div>
      </div>
      <div v-if="isShowEmpty" class="sw-empty-text">暂无数据</div>
    </div>
    <div v-if="bookDetail && !bookDetail.bookrackId && !isShowEmpty" class="sw-loading">正在加载中...</div>
    <sw-info :msgInfo="msgInfo"></sw-info>
    <sw-dialog :dialogInfo="dialogInfo">
      <div slot="body" class="sw-speed-reading-going-animation">
        <h1 v-if="isTimeNum">{{ timeNum }}</h1>
        {{ dialogMsg }}
      </div>
    </sw-dialog>
    <sw-dialog :dialogInfos="dialogInfos">
      <div slot="body" class="sw-speed-reading-going-animation">
        {{ dialogMsgs }}
      </div>
    </sw-dialog>
  </div>
</template>

<script>
import index from "./index.js"

export default {
  mixins: [index],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.sw-speed-reading-flipbook-viewport-mask {
  position: absolute;
  top: 8vh;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: rgba(255, 255, 255, 0.6);
}

.sw-speed-reading-flipbook-viewport-text {
  padding: 0 5vh;
  line-height: 5vh;
  margin: 3vh 0; /* 上下 5vh，左右 0 */
  box-sizing: border-box;
  overflow: hidden;
  font-size: 2.5vh;
  font-family: "宋体";
  font-weight: bold;
  text-indent: 2em; /* 首行缩进两个字符 */
  color: black; /* 设置文本颜色为黑色 */
  z-index: 9999; /* 设置更高的 z-index 值 */
  position: relative; /* 设置文本的相对定位 */
}
</style>
