<template>
  <div id="training" v-show="rankList.length>0" class="sw-speed-reading-book">
    <div class="speed-select" v-if="$route.query.lxmc==='阅读训练'">
      <ul class="rank" v-for="rank of rankList" :key="rank.djid">
        <li>
          <div class="sw-btn" @click="handleRank(rank)" :class="{ 'active': currentRank === rank.djmc }">
            {{ rank.djmc }}
          </div>
          <transition name="custom-classes-transition" enter-active-class="animated fadeInDown">
            <ul class="speed" v-if="currentRank === rank.djmc">
              <template v-for="(item, index) in speed">
                <li :class="{ 'current-speed': item === selectedSpeed }" :key="index" @click.stop="handleSpeed(item)">
                  {{ item }}
                </li>
              </template>
            </ul>
          </transition>
        </li>
      </ul>
    </div>
    <div class="books-container">
      <ul class="books-store" v-show="rawBooks.length">
        <li class="books-row" v-for="(row,index) in (Math.ceil(rawBooks.length / 3))" :key="row">
          <ul>
            <li
                class="book"
                v-for="(book,i) of rawBooks.slice(index * rowNum, index * rowNum + rowNum)"
                :key="book.bookrackId"
                @click="handleSelectBook(book,index * rowNum + i)">
              <img :src="book.sjfm"/>
              <p>{{ book.sjmc }}</p>
            </li>
          </ul>
        </li>
      </ul>
      <div v-if="rawBooks.length===0" style="text-align:center;line-height:70vh;font-size: 2vw">没有更多数据了~~
      </div>
    </div>
    <!--    <el-pagination-->
    <!--        class="pager"-->
    <!--        background-->
    <!--        @current-change="handleCurrentChange"-->
    <!--        @size-change="handleSizeChange"-->
    <!--        :current-page="currentPage"-->
    <!--        :page-sizes="[10, 20, 30, 50]"-->
    <!--        :page-size="pageSize"-->
    <!--        layout="total, sizes, prev, pager, next, jumper"-->
    <!--        :total="total"-->
    <!--    ></el-pagination>-->
    <sw-back-out
        :backPre="`/sw/home?app=${$route.path.substr(4)}&lxid=${$route.query.lxid}&lxmc=${$route.query.lxmc}`"></sw-back-out>
    <div v-if="isLoading" class="sw-loading"></div>
  </div>
</template>

<script>
import config from "../config.js";
import api from "@/api.js";
import {readBooks, readLevel, readLevelDetails} from '@/api/reading'

export default {
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      total: 0,
      currentRank: '初级',
      selectedSpeed: '', // 用于追踪当前选中的速度项
      rankList: [],
      speeds: [],
      speed: [],
      rowNum: 3,
      rawBooks: [],
      loadingClass: "sw-loading",
      emptyText: "",
      isLoading: false,
      flag: false,
      isShowEmptyText: false

    };
  },
  async created() {
    this.isLoading = true;
    await this.fetchRankList();
    await this.queryDetail();
    await this.queryBooks()
    this.selectedSpeed = this.speed[0];
  },
  methods: {
    // 获取图书
    async queryBooks() {
      this.isLoading=true
      const selectedRank = this.rankList.find(rank => rank.djmc === this.currentRank);
      const {result: {list},resultCode} = await readBooks({
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        lxid: this.$route.query.lxid,
        djid: selectedRank.djid,
      })
      if(resultCode===200){
        this.rawBooks = list
        this.isLoading=false
        console.log(this.rawBooks)
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    handleSizeChange(size) {
      this.pageSize = size
    },
    // 获得等级
    async fetchRankList() {
      const {result} = await readLevel()
      this.rankList = result

      console.log(this.rankList, 'hello')
    },
    async queryDetail() {
      const selectedRank = this.rankList.find(rank => rank.djmc === this.currentRank);
      if (selectedRank) {
        const {result: {djqj}} = await readLevelDetails({
          djid: selectedRank.djid,
        });
        this.speed = djqj;
      }
    },
    // 选择等级
    handleRank(rank) {
      this.currentRank = rank.djmc; // 更新当前选中的等级
      this.queryDetail(); // 获取并显示选中等级的明细数据
      this.queryBooks()
    },
    // 选择等级下对应的速度
    handleSpeed(item) {
      console.log(item, '666')
      this.selectedSpeed = item;
    },
    handleSelectBook(book, i) {
      console.log(book,'12323232')
      // 更新 Vuex 中的 book 和 selectedSpeed
      this.$store.commit('speedReading/setBook', book);
      this.$store.commit('speedReading/setSpeed', this.selectedSpeed);
      // 同时将数据保存到 localStorage
      localStorage.setItem('book', JSON.stringify(book));
      localStorage.setItem('selectedSpeed', JSON.stringify(this.selectedSpeed));

      // 导航到其他页面
      const { lxmc } = this.$route.query;

      if (lxmc === '能力测试' || lxmc === '阅读训练' || lxmc === '必读书籍') {
        this.$router.push({
          path: lxmc === '必读书籍' ? 'selectChapter' : 'reading',
          query:{
            lxid:this.$route.query.lxid,
            lxmc:this.$route.query.lxmc
          }
        });
      }
    },
  },

  mounted() {

  }
};
</script>

<style lang="scss" scoped>

$speedWidth: 30vw;
#training {
  $imgWidth: calc((100vh) / 5.8);
  width: 100vw;
  height: 100vh;
  display: flex;
  //.pager {
  //  padding-top: 20px;
  //  text-align: right;
  //}
  .speed-select {
    width: $speedWidth;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    margin: auto;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        text-align: center;
        cursor: pointer;
      }
    }

    ul.rank {
      text-align: right;

      & > li {
        .sw-btn {
          padding: 3vh 8vw;
          margin: 0;
          font-size: 3vw;
          opacity: .7;

          &.active {
            opacity: 1;
          }
        }

        ul.speed {
          background: #fff;
          margin: 0 auto;
          border-radius: 10px;
          width: 22vw;

          li {
            padding: 2vh 0;
            font-size: 2vw;
            cursor: pointer;

            &.current-speed {
              color: #5299d0;
            }
          }
        }

        .fade-enter-active {
          opacity: 1;
          transform: scaleY(1);
          transform-origin: center top;
        }

        .fade-enter,
        .fade-leave-active {
          opacity: 0;
          transform: scaleY(0);
        }
      }
    }

    .sw-button {
      position: absolute;
      right: 2vw;
      bottom: 0;
    }
  }

  .books-container {
    width:100%;
    padding: 6vh 6vw 1vh 5vw;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 6vh) !important;

    ul.books-store {
      li.books-row {
        margin: 0 0 4vh;

        ul {
          margin-bottom: 2vh;
        }

        .book {
          img {
            width: $imgWidth;
            height: calc(#{$imgWidth} * 1.3);
          }

          // box-shadow: -6px 0 0 2px #999, 0 3px 3px 2px #999;
        }
      }
    }

    .sw-empty-text {
      height: 100%;
    }
  }
}
</style>