<template>
  <div class="sw-speed-reading-going">
    <div class="sw-speed-reading-flipbook-viewport">
      <div class="container">
        <div class="sw-speed-reading-flipbook">
          <template>
            <div class="sw-speed-reading-flipbook-viewport-mask"></div>
            <div class="sw-speed-reading-flipbook-viewport-text">{{ getVisibleContent }}</div>

          </template>
          <div style="text-align: right;margin:0 20px 20px 0 ">
            <span style="padding-right: 5px">第{{ currentPage }}页&nbsp;/</span>
            <span>共{{ totalPages }}页</span>
          </div>
        </div>
      </div>
    </div>
    <div class="individualPage" style="position:absolute;opacity:-1;"></div>
    <div class="sw-speed-reading-going-footer sw-speed-reading-going-read-footer">
      <sw-button
          class="sw-btn sw-game-goi-btn"
          :class="{'disabled':isDisabled3?true:false}"
          @click="previousPage">
        上一页
      </sw-button>
      <sw-button
          @click="audioControls"
          class="sw-btn ">
        音乐
        <i class="iconfont" :class="{'iconbar-close':isMusic ? false:true,'iconbar-open':isMusic ? true:false}"></i>
      </sw-button>
      <b class="sw-speed-reading-going-footer-time" style="padding:15px 10px 20px 80px">{{ times }}</b>
      <sw-button
          @click="finished"
          class="sw-btn"
          :class="{'disabled':!complete? true : false}"
          :disabled="!complete"
      >
        阅读完成
      </sw-button>
      <sw-button
          class="sw-btn sw-game-goi-btn"
          :class="{'disabled':isDisabled4?true:false}"
          @click="nextPage">
        下一页
      </sw-button>
    </div>
    <sw-info :msgInfo="msgInfo"></sw-info>
    <sw-dialog :dialogInfo="dialogInfo">
      <div slot="body" class="sw-speed-reading-going-animation">
        {{ dialogMsg }}
      </div>
    </sw-dialog>
    <sw-dialog :dialogInfos="dialogInfos">
      <div slot="body" class="sw-speed-reading-going-animation">
        {{ dialogMsgs }}
      </div>
    </sw-dialog>
    <sw-back-out
        :backPre="`/sw/speedReading/selectChapter?lxid=${$route.query.lxid}&lxmc=${$route.query.lxmc}`"></sw-back-out>
    <div v-if="isLoading" class="sw-loading"></div>
  </div>
</template>
<script>
import "../../js/jquery.min.1.7.js"
import "../../js/turn.js"

var animationReadTime;
import {readBooksChapterContent, readProficiencyTest} from '@/api/reading'

export default {
  data() {
    return {
      dialogInfos: {
        width: '24vw',
        isShow: false //是否开启进入阅读的动画
      },
      dialogMsgs: '',
      times: '00:00:00',
      complete: false,
      booksContent: '',
      currentPage: 0,    // 当前页码
      totalPages: 0,     // 总页数
      isLoading: false,
      isDisabled3: false,
      isDisabled4: false,
      isDisabled5: true,
      isDisabled6: false,
      msgArr: [],
      reStart: '暂停',
      pageNum: 2,//第几页
      // 是否播放音乐
      isStateMusic: false,//点击暂停键之前音乐的状态
      isMusic: false,
      _backMusicDom: undefined,
      time: '00:00:00',
      timeNumder: 0,
      // 增加速度到极值时的提示
      msgInfo: {
        msg: '',
        isShow: false
      },
      // 开始阅读与结束阅读的提示
      dialogMsg: '开始阅读',
      dialogInfo: {
        width: '22vw',
        isShow: false //是否开启进入阅读的动画
      },
    }
  },
  created() {
    this.getContent()
    this.startTest()
  },
  computed: {
    getVisibleContent() {
      const charactersPerPage = 1020; // 与上面相同的字符数
      const startIndex = (this.currentPage - 1) * charactersPerPage;
      const endIndex = this.currentPage * charactersPerPage;
      return this.booksContent.substring(startIndex, endIndex);
    },
  },

  methods: {
    startTest() {
      this.times = '00:00:00';
      this.timerls = setInterval(this.updateTime, 1000);
    },
    updateTime() {
      // 分割时间并转换为秒
      const [hours, minutes, seconds] = this.times.split(':').map(Number);
      let totalSeconds = hours * 3600 + minutes * 60 + seconds;

      // 递增总秒数
      totalSeconds++;

      // 将总秒数转换回时间格式 HH:mm:ss
      const newHours = Math.floor(totalSeconds / 3600);
      const newMinutes = Math.floor((totalSeconds % 3600) / 60);
      const newSeconds = totalSeconds % 60;

      this.times = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;
    },

    async finished() {
      // 停止时间计时器
      clearInterval(this.timerls);
      await this.getTesTachievement()

    },
    timeStringToSeconds(timeString) {
      const parts = timeString.split(":");
      if (parts.length === 3) {
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);
        const seconds = parseInt(parts[2]);
        // 计算总秒数
        return (hours * 3600) + (minutes * 60) + seconds;
      } else {
        return 0; // 如果格式不正确，返回 0 或者其他合适的默认值
      }
    },
    async getTesTachievement() {
      const totalSeconds = this.timeStringToSeconds(this.times);
      this.dialogInfos.isShow = true
      this.dialogMsgs = `本章阅读结束，用时${totalSeconds}秒`
      setTimeout(() => {
        this.$router.go(-1)
      }, 3000)
    },
    async getContent() {
      this.isLoading = true
      const storedBookContent = localStorage.getItem('bookContent');
      const obj = storedBookContent ? JSON.parse(storedBookContent) : this.$store.state.speedReading.bookContent;
      const {resultDesc, resultCode} = await readBooksChapterContent({
        sjid: obj.sjid,
        sjzjid: obj.sjzjid
      })
      if (resultCode === 200) {
        this.booksContent = resultDesc
        this.isLoading = false
      }
      console.log(this.booksContent.length, '总字符数')
      // 计算总页数，根据需要将文章内容分页
      const charactersPerPage = 1020; // 每页字符数（根据实际情况调整）
      this.totalPages = Math.ceil(this.booksContent.length / charactersPerPage);

      // 初始化当前页码
      this.currentPage = 1;
    },
    // 上一页
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    // 下一页
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
      const charactersPerPage = 1020; // 每页字符数（根据实际情况调整）
      if (this.currentPage === Math.ceil(this.booksContent.length / charactersPerPage)) {
        this.complete = true
      }
    },
    // 对内容进行分页
    pageInte(words) {
      var newPage = document.querySelector('.individualPage');

      var pageText = null;
      for (var i = 0; i < words.length; i++) {
        if (!pageText && i != 0) {
          i = i - 1
        }
        var betterPageText = pageText ? pageText + words[i] : words[i];
        newPage.innerHTML = betterPageText;
        if (newPage.scrollHeight > document.body.offsetHeight * (86 / 100) - 2 * document.body.offsetHeight * (5 / 100)) {
          this.msgArr.push(pageText)
          newPage.innerHTML = pageText;
          pageText = null;

        } else {
          pageText = betterPageText;
        }
      }
      this.$nextTick(() => {
        this.msgArr.push(betterPageText)
        this.isLoading = false
        this.initPage()
      })
    },
    // 初始化页面书籍
    initPage() {
      this.msgArr.unshift('')
      this.msgArr.push(' ')
      this.$nextTick(() => {
        $('.sw-speed-reading-flipbook').turn({
          width: '95vw',
          height: '86vh',
          page: 2,//起始页
          gradients: true,
          autoCenter: true,
          when: {
            turning: (event, page, pageObject) => {
              if (this.pageNum < page && this.isDisabled6) {
                return false
              }
              if (this.pageNum > page && this.isDisabled6) {
                return false
              }
              if (page == 1 || page == this.msgArr.length) {
                return false
              } else {
                let pageNum = this.pageNum
                this.pageNum = page
                this.readingBook(pageNum > page ? "-" : "+")
              }
            }
          }
        });
        this.pageNum = 2
        this.isDisabled3 = true
        this.isMusic = false
        this._backMusicDom.pause();
        this.time = "00:00:00"
        this.timeNumder = 0
        this.countTime()
      })
    },
    // 播放音效
    audioControls() {
      if (this._backMusicDom.paused) {
        this._backMusicDom.play();
        this.isMusic = true
        this.isStateMusic = true
      } else {
        this._backMusicDom.pause();
        this.isMusic = false
        this.isStateMusic = false
      }
    },
    // 跳转到下一页和上一页
    readBook(name) {
      if (this.msgInfo.isShow) {
        this.msgInfo.isShow = false
      }
      if (name == '+') {
        if (!this.isDisabled4) {
          $(".sw-speed-reading-flipbook").turn("next");
        }
      } else {
        if (!this.isDisabled3) {
          $(".sw-speed-reading-flipbook").turn("previous");
        }
      }
      if (!this.isDisabled3 || !this.isDisabled4) {
        this.readingBook(name)
      }
    },
    readingBook(name) {
      if (name == '-') {
        this.isDisabled4 = false
        if (this.pageNum >= 3) {
          // this.pageNum -= 3
          if (this.pageNum == 3) {
            this.isDisabled3 = true
            this.msgInfo.isShow = true
            this.msgInfo.msg = `文章已经在最开始的位置，请向后继续阅读！`
          }
        } else {
          this.msgInfo.isShow = true
          this.msgInfo.msg = `文章已经在最开始的位置，请向后继续阅读！`
        }
      } else {
        this.isDisabled3 = false
        if (this.pageNum <= this.msgArr.length - 1) {
          // this.pageNum += 2
          if (this.pageNum == this.msgArr.length - 1) {
            this.isDisabled4 = true
            this.msgInfo.isShow = true
            this.msgInfo.msg = `文章已经在最末尾的位置，请点击完成后开始测试阅读情况！`
            this.isDisabled5 = false
          }
        } else {
          this.msgInfo.isShow = true
          this.msgInfo.msg = `文章已经在最末尾的位置，请点击完成后开始测试阅读情况！`
          this.isDisabled5 = false
        }
      }
    },
    // 时间
    countTime(timeNumder) {
      this.timeNumder = timeNumder || 0
      let timeAin = () => {
        this.timeNumder++
        let min = Math.floor(this.timeNumder % 3600);
        this.time = (Math.floor(this.timeNumder / 3600) > 9 ? Math.floor(this.timeNumder / 3600) : ('0' + Math.floor(this.timeNumder / 3600)))
            + ":" + (Math.floor(min / 60) > 9 ? Math.floor(min / 60) : ('0' + Math.floor(min / 60)))
            + ":" + (this.timeNumder % 60 > 9 ? this.timeNumder % 60 : ('0' + this.timeNumder % 60));
      }
      animationReadTime = setInterval(function () {
        timeAin();
      }, 1000);
    },
    stopTime() {
      this.isDisabled6 = !this.isDisabled6
      if (this.isDisabled6) {
        clearInterval(animationReadTime);
        this.reStart = '开始'
        this.isDisabled3 = true
        this.isDisabled4 = true
        this._backMusicDom.pause();
        this.isMusic = false
      } else {
        this.countTime(this.timeNumder)
        this.reStart = '暂停'
        if (this.isStateMusic) {
          this._backMusicDom.play();
          this.isMusic = true
          this.isStateMusic = true
        } else {
          this.isMusic = false
          this.isStateMusic = false
        }
        if (this.pageNum == 2) {
          this.isDisabled3 = true
        } else {
          this.isDisabled3 = false
        }
        if (this.pageNum == this.msgArr.length - 4) {
          this.isDisabled4 = true
        } else {
          this.isDisabled4 = false
        }
      }
    },
    // finished() {
    //   if (!this.isDisabled5) {
    //     clearInterval(animationReadTime);
    //     this.dialogInfo.isShow = true
    //     this.dialogMsg = '闭目回想，开始测试'
    //     this.$store.commit('speedReading/setTotalTimes', this.timeNumder / 60)
    //     setTimeout(() => {
    //       this.dialogInfo.isShow = false
    //       this.$router.push({
    //         path: "/sw/speedReading/testing",
    //       });
    //     }, 5000);
    //   }
    // },
  },
  mounted() {
    this._backMusicDom = document.querySelector('#background-audio');
  },
  beforeDestroy() {
    this.resetAutoReading();
    // 组件销毁前清除计时器
    if (this.timerls) {
      clearInterval(this.timerls);
    }
  },
  destroyed() {
    clearInterval(animationReadTime);
    this._backMusicDom.pause();
    this.isMusic = false
  },
}
</script>
<style lang="scss" scoped>
.sw-speed-reading-flipbook-viewport-text {
  padding: 0 5vh;
  line-height: 5vh;
  margin: 5vh 0; /* 上下 5vh，左右 0 */
  box-sizing: border-box;
  overflow: hidden;
  font-size: 2.5vh;
  font-family: "宋体";
  font-weight: bold;
  text-indent: 2em; /* 首行缩进两个字符 */
  color: black; /* 设置文本颜色为黑色 */
  z-index: 9999; /* 设置更高的 z-index 值 */
  position: relative; /* 设置文本的相对定位 */
}
</style>