import {readBooksContent, readProficiencyTest} from '@/api/reading'

var stop;
var animationing
var animationReadTime;

export default {
    data() {
        return {
            complete: false, booksContent: '', currentPage: 0,    // 当前页码
            totalPages: 0,     // 总页数
            scrollPosition: 0, // 当前滚动的位置
            scrollSpeed: 0, // 滚动速度（行/毫秒）
            scrollTimer: null, // 定时器变量
            selectedSpeed: '', isShowEmpty: false, isTimeNum: true,//是否展示倒计时的时间
            isRead: true,//是否处于自动阅读的状态
            isReading: false,//是否处于阅读状态
            totalTimes: 0,//总耗时（分秒）
            allSecond: 0,//总耗时（毫秒数）
            pageSize: 6, //每页显示的条数
            // 文章的详情
            bookDetail: {
                bookrackId: "",//文章ID
                bookName: "",//文章名字
                bookWordNumber: "",//文章总字数
            }, pMax: 0, content: '', paragraphs: [], // Array to store paragraphs
            msgArr: [],//文章的显示内容
            // 增加速度到极值时的提示
            msgInfo: {
                msg: '', isShow: false
            }, // 是否播放音乐
            isMusic: false, _backMusicDom: undefined, // 自动阅读
            timeNum: 3,//进入阅读的倒计时
            // 开始阅读与结束阅读的提示
            dialogMsg: '开始阅读', dialogMsgs: '', dialogInfo: {
                width: '26vw', isShow: false //是否开启进入阅读的动画
            }, dialogInfos: {
                width: '24vw', isShow: false //是否开启进入阅读的动画
            }, isDisabled: false,//是否能继续向上增加
            isDisabled2: false,//是否能继续减小
            totalMinute: 0, //每分钟需要读的字数
            // rowNum: 25, //每行显示的字数
            rowTotal: 0, //文章的总行数
            rowH: 9, //每行的高度 vh
            startTop: 0, //文章的起始位置
            speedStep: 200,//每次增减速度的步长
            isDisabled3: false, isDisabled4: false, isDisabled5: true, isShowBtn: true,//手动点击防止点击过快导致页面不更新的问题
            startStep: 0, times: '00:00:00', timerls: null
        };
    }, watch: {
        'timeNum'(val) {
            if (val == 0) {
                this.isTimeNum = false
                clearInterval(animationing);
                this.dialogInfo.isShow = false
                setTimeout(() => {
                    this.reading();
                    this.timeNumder++
                }, 1000);
            }
        }
    }, created() {
        this.init()
        // 设置 totalMinute 的初始值为 speedRange.min
        this.totalMinute = this.speedRange.min;
        this.totalTimes = this.calculateTotalTime(this.bookDetail.bookWordNumber, this.totalMinute);
    },

    beforeDestroy() {
        this.resetAutoReading();
        // 组件销毁前清除计时器
        if (this.timerls) {
            clearInterval(this.timerls);
        }
    }, computed: {
        speedRange() {
            // 解析 selectedSpeed 字段，提取最小值和最大值
            const match = this.selectedSpeed.match(/(\d+)-(\d+)/);
            if (match) {
                const min = parseInt(match[1]);
                const max = parseInt(match[2]);
                return {min, max};
            }
            // 如果无法匹配正确的格式，可以设置默认值或者进行错误处理
            return {min: 0, max: 0}; // 或其他默认值
        }, // isDisabled() {
        //     return this.totalMinute >= this.speedRange.max;
        // },
        // isDisabled2() {
        //     return this.totalMinute <= this.speedRange.min;
        // },

        getVisibleContent() {
            const charactersPerPage = 950; // 与上面相同的字符数
            const startIndex = (this.currentPage - 1) * charactersPerPage;
            const endIndex = this.currentPage * charactersPerPage;
            return this.booksContent.substring(startIndex, endIndex);
        },

    }, methods: {
        init() {
            this.fetchBookDetail().then(() => {
                const content = this.content;
                const paragraphLength = 50;
                const paragraphs = [];
                let currentParagraph = '';

                // 正则表达式来检测中文字符
                const containsChinese = /[\u4e00-\u9fa5]/.test(content);

                if (containsChinese) {
                    // 中文文本处理方式
                    for (let i = 0; i < content.length; i += paragraphLength) {
                        paragraphs.push(content.slice(i, i + paragraphLength));
                    }
                } else {
                    // 英文文本处理方式
                    const words = content.split(/\s+/); // 用空格分割内容为单词

                    for (let i = 0; i < words.length; i++) {
                        const word = words[i];
                        if (currentParagraph.length + word.length <= paragraphLength) {
                            currentParagraph += word + ' ';
                        } else {
                            paragraphs.push(currentParagraph.trim());
                            currentParagraph = word + ' ';
                        }
                    }

                    if (currentParagraph.trim() !== '') {
                        paragraphs.push(currentParagraph.trim());
                    }
                }

                this.paragraphs = paragraphs;
            });
            clearInterval(stop);
            clearInterval(animationReadTime);
            if (this.$store.state.speedReading.bookId) {
                this.$store.commit("speedReading/setBookId", this.$store.state.speedReading.bookId);
            }
        },

        // 点击返回按钮
        clickBack() {
            if (this.isReading) {
                this.resetAutoReading();
                this.isReading = false
                this.startTop = 0
                document.getElementById("sw-speed-reading-going-article").style.top = this.startTop
                clearInterval(stop);
                this._backMusicDom.pause();
                this.isMusic = false
            } else {
                this.$router.go(-1)
                this.resetAutoReading();
            }
        }, // 点击开始阅读
        startReading() {
            this.isTimeNum = true
            this.readingAnimation()
            this.resetAutoReading()
        }, startTest() {
            this.isReading = true;
            this.times = '00:00:00';
            this.timerls = setInterval(this.updateTime, 1000);
        }, updateTime() {
            // 在计时器触发时更新时间
            // 这里假设 isReading 和 times 已经定义
            if (this.isReading) {
                // 分割时间并转换为秒
                const [hours, minutes, seconds] = this.times.split(':').map(Number);
                let totalSeconds = hours * 3600 + minutes * 60 + seconds;

                // 递增总秒数
                totalSeconds++;

                // 将总秒数转换回时间格式 HH:mm:ss
                const newHours = Math.floor(totalSeconds / 3600);
                const newMinutes = Math.floor((totalSeconds % 3600) / 60);
                const newSeconds = totalSeconds % 60;

                this.times = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;
            }
        },

        // ***********************************************自动阅读
        // 加减速度
        speedSelect(action) {
            if (action === '+') {
                if (this.totalMinute < this.speedRange.max) {
                    this.totalMinute = Math.min(this.totalMinute + 200, this.speedRange.max);
                } else {
                    this.msgInfo.isShow = true;
                    this.msgInfo.msg = `每次增加的速度是200字/分钟,最大增加到${this.speedRange.max}字/分钟，现已达到可增加的最大值！`;
                }
            } else if (action === '-') {
                if (this.totalMinute > this.speedRange.min) {
                    this.totalMinute = Math.max(this.totalMinute - 200, this.speedRange.min);
                } else {
                    this.msgInfo.isShow = true;
                    this.msgInfo.msg = `每次减少的速度是200字/分钟,最小减到${this.speedRange.min}字/分钟，现已达到可减小的最小值！`;
                }
            }
            // 计算总耗时
            this.totalTimes = this.calculateTotalTime(this.bookDetail.bookWordNumber, this.totalMinute);
        }, calculateTotalTime(totalWords, wordsPerMinute) {
            const totalTimeInSeconds = totalWords / wordsPerMinute * 60;
            const minutes = Math.floor(totalTimeInSeconds / 60);
            const seconds = Math.floor(totalTimeInSeconds % 60);
            return `${minutes}分${seconds}秒`;
        },


        reading() {
            const totalMilliseconds = this.calculateTotalMilliseconds(this.totalTimes); // 将总耗时转换为毫秒
            this.scrollSpeed = this.calculateScrollSpeed(totalMilliseconds);

            const scrollInterval = 10; // 每次滚动的时间间隔（毫秒）
            let elapsedMilliseconds = 0;

            this.scrollTimer = setInterval(() => {
                elapsedMilliseconds += scrollInterval;
                if (elapsedMilliseconds < totalMilliseconds) {
                    this.scrollPosition += this.scrollSpeed * scrollInterval;
                    if (this.scrollPosition >= this.paragraphs.length) {
                        this.scrollPosition = this.paragraphs.length - 1;
                    }
                    // 更新滚动位置
                    document.getElementById("sw-speed-reading-going-article").style.top = -this.scrollPosition * this.rowH + "vh";
                } else {
                    clearInterval(this.scrollTimer); // 停止滚动
                    setTimeout(() => {
                        this.dialogInfos.isShow = true
                        this.dialogMsgs = '阅读完毕，返回阅读训练'
                        this._backMusicDom.pause();
                        this.isMusic = false

                    }, 500);
                    setTimeout(() => {
                        this.dialogInfos.isShow = false
                        this.isReading = false
                        console.log(this.timeNum, '倒计时时间')
                        // this.$router.push({
                        //     path: `/sw/speedReading/reading?lxid=${$route.query.lxid}&lxmc=${$route.query.lxmc}`,
                        // });
                    }, 3000);
                    // 阅读结束的逻辑
                }
            }, scrollInterval);
        }, resetAutoReading() {
            clearInterval(this.scrollTimer); // 停止滚动
            this.scrollPosition = 0;
            // 将滚动位置重置到初始位置
            document.getElementById("sw-speed-reading-going-article").style.top = "0";
        }, calculateTotalMilliseconds(totalTime) {
            // 将总耗时格式（例如：'5分30秒'）转换为毫秒
            const [minutes, seconds] = totalTime.split(/[分秒]/).filter(Boolean).map(Number);
            return (minutes * 60 + seconds) * 1000;
        }, calculateScrollSpeed(totalMilliseconds) {
            // 计算滚动速度，确保文字在总时间内滚动完整
            return this.paragraphs.length / totalMilliseconds;
        }, // 开始阅读动画
        readingAnimation() {
            this.timeNum = 3
            this.dialogInfo.isShow = true

            this.$nextTick(() => {
                this.isReading = true;
            })
            let readAni = () => {
                this.timeNum--
            }
            animationing = setInterval(function () {
                readAni();
            }, 1000);

        }, // 播放音效
        audioControls() {
            if (this._backMusicDom.paused) {
                this._backMusicDom.play();
                this.isMusic = true
            } else {
                this._backMusicDom.pause();
                this.isMusic = false
            }
        }, // 获取书的内容
        async fetchBookDetail() {
            const storedBook = localStorage.getItem('book');
            const storedSelectedSpeed = localStorage.getItem('selectedSpeed');

            const obj = storedBook ? JSON.parse(storedBook) : this.$store.state.speedReading.book;
            this.selectedSpeed = storedSelectedSpeed ? JSON.parse(storedSelectedSpeed) : this.$store.state.speedReading.selectedSpeed;

            this.bookDetail = {
                bookrackId: obj.sjid, bookName: obj.sjmc, bookWordNumber: obj.sjzs,
            }

            const {resultDesc} = await readBooksContent({
                sjid: obj.sjid, sjmc: obj.sjmc, sjzs: obj.sjzs,
            })
            this.content = resultDesc
            this.booksContent = resultDesc
            // 计算总页数，根据需要将文章内容分页
            const charactersPerPage = 950; // 每页字符数（根据实际情况调整）
            this.totalPages = Math.ceil(this.booksContent.length / charactersPerPage);

            // 初始化当前页码
            this.currentPage = 1;
        }, // 上一页
        previousPage() {
            console.log('上一页')
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },

        // 下一页
        nextPage() {
            console.log('下一页')
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
            const charactersPerPage = 920; // 每页字符数（根据实际情况调整）
            if (this.currentPage === Math.ceil(this.booksContent.length / charactersPerPage)) {
                this.complete = true

            }
        },


        async finished() {
            // 停止时间计时器
            clearInterval(this.timerls);
            await this.getTesTachievement()

        },

        timeStringToSeconds(timeString) {
            const parts = timeString.split(":");
            if (parts.length === 3) {
                const hours = parseInt(parts[0]);
                const minutes = parseInt(parts[1]);
                const seconds = parseInt(parts[2]);
                // 计算总秒数
                return (hours * 3600) + (minutes * 60) + seconds;
            } else {
                return 0; // 如果格式不正确，返回 0 或者其他合适的默认值
            }
        }, async getTesTachievement() {
            const storedBook = localStorage.getItem('book');
            const obj = storedBook ? JSON.parse(storedBook) : this.$store.state.speedReading.book;
            const totalSeconds = this.timeStringToSeconds(this.times);
            const {result} = await readProficiencyTest({
                sc: totalSeconds, sjid: obj.sjid, sjzs: obj.sjzs,
                yhid:sessionStorage.getItem('yhid'),
                yhmc:sessionStorage.getItem('yhmc')
            })
            if (result === 1) {
                this.dialogInfos.isShow = true
                const fraction = Math.round(obj.sjzs / totalSeconds)
                this.dialogMsgs = `恭喜测试结束，本次得分${fraction}分`
                setTimeout(() => {
                    this.$router.go(-1)
                }, 3000)
            }
        },

        // *******************************************************不自动阅读

        countTime() {
            this.timeNumder = 0
            let timeAin = () => {
                this.timeNumder++
                let min = Math.floor(this.timeNumder % 3600);
                this.time = (Math.floor(this.timeNumder / 3600) > 9 ? Math.floor(this.timeNumder / 3600) : ('0' + Math.floor(this.timeNumder / 3600))) + ":" + (Math.floor(min / 60) > 9 ? Math.floor(min / 60) : ('0' + Math.floor(min / 60))) + ":" + (this.timeNumder % 60 > 9 ? this.timeNumder % 60 : ('0' + this.timeNumder % 60));
            }
            animationReadTime = setInterval(function () {
                timeAin();
            }, 1000);
        }, readBook(name) {
            if (this.startStep >= 0) {
                this.isDisabled3 = true
            }
            if (!this.isDisabled3 || !this.isDisabled4) {
                this.readingBook(name)
            }
        }, readingBook(name) {
            this.isShowBtn = false
            if (name == '-') {
                if (!this.isDisabled3) {
                    this.startStep += this.rowH * this.pageSize;
                    this.isDisabled4 = false
                } else {
                    this.isDisabled3 = true
                    this.msgInfo.isShow = true
                    this.msgInfo.msg = `文章已经在最开始的位置，请向后继续阅读！`
                }
            } else {
                if (!this.isDisabled4) {
                    this.startStep -= this.rowH * this.pageSize;
                    this.isDisabled3 = false
                } else {
                    this.isDisabled4 = true
                    this.msgInfo.isShow = true
                    this.msgInfo.msg = `文章已经在最末尾的位置，请点击完成后开始测试阅读情况！`
                }
            }
            this.$nextTick(() => {
                this.isShowBtn = true
            })
            // 设置文章的起始top
            document.getElementById("sw-speed-reading-going-article").style.top = this.startStep + "vh";
            let articleTop = parseFloat(parseInt(document.getElementById("sw-speed-reading-going-article").style.top))
            // 在文章最后页文字出来之后就进行提示
            if (articleTop - this.rowH * this.pageSize <= this.startTop - this.rowTotal * this.rowH) {
                this.isDisabled4 = true
                this.msgInfo.isShow = true
                this.msgInfo.msg = `文章已经在最末尾的位置，请点击完成后开始测试阅读情况！`
                this.isDisabled5 = false
            }
        },
    }, mounted() {
        this._backMusicDom = document.querySelector('#background-audio');
    }, destroyed() {
        clearInterval(stop);
        clearInterval(animationReadTime);
        this._backMusicDom.pause();
        this.isMusic = false
    },
};