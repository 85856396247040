<template>
    <div style="position:absolute;height:100%;width:100%;">
        <div  v-show="subjectList && subjectList.length>0 && subjectList[pageNum]">
            <sw-panel title="测试题">
                <div slot="body">
                    <div v-if="subjectList && subjectList.length>0 && subjectList[pageNum]" class="sw-speed-reading-testing-question" >
                        {{pageNum +1}}.{{ subjectList[pageNum].testSubjectName }}
                        <ul class="sy-game-answers-list" v-if="pageNum <= subjectList.length">
                            <li
                                v-for="ans in subjectList[pageNum].answers"
                                :key="ans.answerId"
                                @click="checkAnser(ans)">
                                {{ ans.answerContent }}
                                <i v-show="currentAnswer && currentAnswer.answerId == ans.answerId " class="sy-game-answers-icon"></i>
                            </li>
                        </ul>
                    </div>
                    <span v-else> {{ emptyText }}</span>
                </div>
                <div slot="footer">
                    <sw-button class="sw-btn" @click="clickBack">返回</sw-button>
                    <sw-button class="sw-btn" @click="checkNext">下一题</sw-button>
                </div>
            </sw-panel>
            <sw-info :msgInfo="msgInfo"></sw-info>
        </div>
        <span v-if="subjectList.length == 0"  class="sw-empty-text">
            
        <sw-button :sound="false" @click="clickBack" class="sw-btn-back" title="返回">返回</sw-button>
             {{ emptyText }}
        </span>
    </div>
</template> 

<script>
import api from "@/api.js";
import config from '../config.js';
let intNum = 0;
export default {
    data() {
        return {
            pageNum: 0,
            pageMax: 0,
            anserGrade:0,
            subjectList: [],
            currentAnswer: null,
            emptyText: null,
            // 提示
            msgInfo:{
                msg:'',
                isShow:false
            },
        };
    },
    created() {
        if(!this.$store.state.speedReading.bookId){
            this.$router.push({
                path: '/sw/speedReading/reading'
            })
        }else{
            this.fetchSubjectByBook();
        }
    },
    methods:{
        fetchSubjectByBook(){
            if (this.$store.state.network && this.$store.state.userInfo.token) {
                let method 
                let params
                if(this.$store.state.speedReading.currentCheckPoint != 4){
                   method = api.SR_selectSubjectByBook
                   params = {
                        bookrackId: this.$store.state.speedReading.bookId
                   }
                }else{
                   method = api.SR_selectSpecialBooksChapterTesttByBookChapterId
                   params = {
                       specialBookChapterId: this.$store.state.speedReading.specialBookChapterId
                   }
                }
                if(method){
                    method(params).then((res)=>{
                        if(res && res.status==200){
                            this.subjectList = res.data
                            this.pageMax = this.subjectList.length
                            
                            if (this.subjectList.length == 0) {
                                this.emptyText = "暂无数据";
                            }
                        }
                    });
                }
            }else {
                this.subjectList = config.tests.filter(item => {
                    return ( item.bookrackId === this.$store.state.speedReading.bookId );
                });
                this.pageMax = this.subjectList.length
                this.isLoading = false;
                if (this.subjectList.length == 0) {
                    this.emptyText = "暂无数据";
                }
            }
        },
        checkAnser(ans) {
            this.currentAnswer = ans;
        },
        checkNext(){
            if ((new Date().getTime() - intNum) < 10) { //只要点击相隔小于100毫秒就不继续
                intNum = 0;
                return false;
            }
            intNum = new Date().getTime();
            if(this.currentAnswer && this.currentAnswer.answerId){
                this.subjectList[this.pageNum].answers.forEach(s => {
                    if (s.answerId == this.currentAnswer.answerId) {
                        if(this.currentAnswer.testSubjectId == this.subjectList[this.pageNum].testSubjectId){
                            if (this.pageMax - 1 > this.pageNum) {
                                this.pageNum++;
                                if (this.currentAnswer.answeState) {
                                    this.anserGrade += 20;
                                }
                            } else {
                                if (this.currentAnswer.answeState) {
                                    this.anserGrade += 20;
                                }
                                this.$store.commit('speedReading/setAnserGrade', this.anserGrade);
                                if( this.$route.path != '/sw/speedReading/result'){
                                    this.$router.push({
                                        path:"/sw/speedReading/result"
                                    });
                                }
                            }
                        }else{
                            this.msgInfo.isShow = true
                            this.msgInfo.msg = '请先选择答案！'
                        }
                    }
                })
            }else{
                this.msgInfo.isShow = true
                this.msgInfo.msg = '请先选择答案！'
            }
        },
        clickBack(){
            this.$router.push(
                '/sw/speedReading/'+((this.$store.state.speedReading.currentCheckPoint == '3' || this.$store.state.speedReading.currentCheckPoint == '4')  ? 'training' : 'evaluation')
                // '/sw/speedReading/training'
            )
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
